<template>
  <!-- <div :style="'background-image: url(' + backgroundImage + '); background-size: cover; background-repeat: no-repeat;'"> -->
  <div style="background-color: rgba(30, 28, 29, 1);">
    <b-container fluid>
      <b-row align-v="center" align-h="center" style="height: 100vh">
        <b-col sm="6" md="4" align-v="center">
          <b-card align="center" align-v="center" id="at" style="background-color: rgba(170, 70, 12, 1); margin-top: 50px;border-radius: 20px; box-shadow: 5px 5px 10px rgba(170, 70, 12, 1)">
            <div>
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col cols="8">
                  <h4 style="color: white;"><span @click="f_goToMainPage()" style="cursor: pointer;">WaiWebCustomer</span></h4>
                </b-col>
                <b-col cols="2">
                  <template v-if="d_showLoginButtonClicked">
                    <!-- <img src="../../static/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 20px; height: 20px;" /> -->
                  </template>
                </b-col>
              </b-row>
            </div>
            <b-card style="margin-top: 0px;background-color: rgba(255, 255, 255, 0.7);border-radius: 15px;">
              <h5 style="color: black;">Giriş Ekranı</h5>
              <b-form @submit.prevent="submit">
                <b-form-group validated label-for="normalEmail">
                  <b-form-input :autofocus="true" id="normalEmail" type="text" placeholder="Kullanıcı adınızı girin" required autocomplete="email" style="border: 1px solid rgba(170, 70, 12, 1);" v-model="credentials.username" :formatter="toLowerCase"></b-form-input>
                </b-form-group>
                <b-form-group validated label-for="normalPass">
                  <b-form-input id="normalPass" type="password" placeholder="Şifrenizi girin" required autocomplete="current-password" style="border: 1px solid rgba(170, 70, 12, 1);" v-model="credentials.password"></b-form-input>
                </b-form-group>
                <div slot="footer">
                  <template v-if="!d_showLoginButtonClicked">
                    <b-button type="reset" size="sm" style="margin: 2px; background-color: rgba(170, 70, 12, 1);width: 100px;color: white; border-color: rgba(170, 70, 12, 1);"><i class="fa fa-ban" style="color: #f87574;"></i>&nbsp;temizle
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="sm" style="margin: 2px; background-color: rgba(170, 70, 12, 1);width: 100px;color: white; border-color: rgba(170, 70, 12, 1);"><i class="fa fa-ban" style="color: #f87574;"></i>&nbsp; işlem
                    </b-button>
                  </template>
                  <template v-if="!d_showLoginButtonClicked">
                    <b-button type="submit" size="sm" style="margin: 2px; background-color: rgba(170, 70, 12, 1);width: 100px;color: white; border-color: rgba(170, 70, 12, 1);"><i class="fa fa-sign-in" style="color: #45dc45;"></i>&nbsp;giriş
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button size="sm" style="margin: 2px; background-color: rgba(170, 70, 12, 1); width: 100px; color: white; border-color: rgba(170, 70, 12, 1);"><i class="fa fa-sign-in" style="color: #45dc45;"></i>&nbsp; yapılıyor
                    </b-button>
                  </template>
                  <br>
                  <!-- <b-button :disabled="d_showLoginButtonClicked" @click="f_goToResetPassword()" size="sm" style="background-color: rgba(170, 70, 12, 1);width: 200px;color: #c1e4e1;"><i class="fa fa-question" style="color: #f87574;"></i>&nbsp;şifremi unuttum
                  </b-button> -->
                </div>
              </b-form>
            </b-card>
            <div>
              <span style="color:white; text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;">A New Era is Beginning by WisdomEra</span>
            </div>
            <div>
              <span @click="f_goToMainPage()" style="cursor: pointer; color: white;">Ana sayfaya dön</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import auth from '@/auth';
import wdm16 from '@/wdm16';
import store from '@/store';
import { mapGetters } from 'vuex';
export default {
  name: 'login',
  computed: mapGetters({
    StoreLoading: 'loading'
  }),
  data () {
    return {
      wdm16: wdm16.wdm16,
      backgroundImage: '',
      // backgroundImage: require('../../static/img/LoginBackground.png'),
      credentials: {
        username: '',
        password: ''
      },
      error: '',
      store: store,
      d_showLoginButtonClicked: false
    };
  },
  mounted: function () {
    auth.logout();
  },
  methods: {
    toLowerCase (value, event) {
      return value.toLowerCase()
    },
    /*
      f_startLogin: function () {
        this.d_showLoginButtonClicked = true;
        setTimeout(function () {
          this.d_showLoginButtonClicked = false;
        }.bind(this), 5000)
      },
    */
    f_goToResetPassword: function () {
      this.$router.push('/resetpassword');
    },
    submit () {
      this.d_showLoginButtonClicked = true;
      let redirect_url = '/';
      let router_query = this.$router.currentRoute.query;
      if (router_query.new_url) {
        redirect_url = router_query.new_url;
        for (let query in router_query) {
          if (query !== 'new_url') {
            redirect_url += '&' + query + '=' + router_query[query];
          }
        }
      }
      var credentials = {
        username: this.credentials.username,
        password: this.credentials.password,
        multi_account_password: 0,
        not_control_password: 0
      };
      auth.login(this, credentials, redirect_url).then(resp => {
        this.d_showLoginButtonClicked = false;
      }, resp => {
        this.d_showLoginButtonClicked = false;
      });
      /*
        if (!this.d_showLoginButtonClicked) {
          this.d_showLoginButtonClicked = true;
          // this.f_startLogin();
          var credentials = {
            username: this.credentials.username,
            password: this.credentials.password,
            multi_account_password: 0,
            not_control_password: 0
          };
          auth.login(this, credentials, '/');
        }
      */
    },
    f_goToMainPage: function () {
      this.$router.push({ path: '/welcome', query: {} });
    },
  },
  watch: {
    /*
      'StoreLoading.status': function () {
        this.d_showLoginButtonClicked = this.StoreLoading.status;
      }
    */
  }
}

</script>

<style type="text/css">
canvas {
  background-color: #000;
}

</style>

